import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { CommonResponse } from "@/api/response";
import { RegisterRequest } from "@/api/remind/request";
import * as RemindAPI from "@/api/remind";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "remind/register";

/**
 * プッシュメッセージ定期自動配信設定登録API（/regist-remind）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Register extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  // MutationActions
  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const commonResponse = await RemindAPI.register(registerRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clear() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Register);
