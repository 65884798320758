import service from "@/api/service";
import { GetResponse } from "@/api/remind/response";
import { CommonResponse } from "@/api/response";
import { GetRequest, RegisterRequest } from "@/api/remind/request";

/**
 * プッシュメッセージ定期自動配信設定取得APIをコールします。
 *
 * @param getRequest
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-remind", getRequest);
  return response.data as GetResponse;
}

/**
 * プッシュメッセージ定期自動配信設定登録APIをコールします。
 *
 * @param registerRequest
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-remind", registerRequest);
  return response.data as CommonResponse;
}
