import service from "@/api/service";
import { GetResponse } from "@/api/remind/response";
import { GetRequest } from "@/api/remind/request";

/**
 * プッシュメッセージ定期自動配信設定対象ユーザー数取得APIをコールします。
 *
 * @param getRequest
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-remind-count", getRequest);
  return response.data as GetResponse;
}
