import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import {
  GetRequest as RemindRequest,
  RegisterParams
} from "@/api/remind/request";
import { GetRequest as RemindCountRequest } from "@/api/remind-count/request";
import {
  GetBirthday,
  GetComplete,
  GetLink,
  GetNext,
  GetParams,
  GetRepeat,
  GetResult,
  GetReview,
  GetTicket
} from "@/api/remind/response";
import { GetRequest as StampRequest } from "@/api/stamp-list/request";
import { GetRequest as ShitenRequest } from "@/api/shiten-list/request";
import { GetRequest as TicketRequest } from "@/api/ticket-list/request";
import { RegisterRequest } from "@/api/remind/request";
import RemindGet from "@/store/remind/get";
import RemindRegister from "@/store/remind/register";
import RemindCountGet from "@/store/remind-count/get";
import StampListGet from "@/store/stamp-list/get";
import ShitenListGet from "@/store/shiten-list/get";
import TicketListGet from "@/store/ticket-list/get";
import PushUserCountGet from "@/store/push-user-count/get";
import Flash from "@/store/common/flash";
import _ from "lodash";
import { BusinessServiceStorage } from "@/store/business-service-storage";

/**
 * 店舗別パラメータ
 */
interface ShopQuestion {
  name: string;
  values: { [key: number]: string };
}

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIFormRow, quillEditor }
})
export default class Remind extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  headingMain = "プッシュメッセージ";
  headingSub = "Push notifications";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "プッシュメッセージ", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "push-notifications" }
    },
    { text: "定期配信設定", disabled: true }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  // ローディングステータス
  isLoading = false;

  // 再来店促進配信ダイアログ
  showVisitDialog = false;
  visitSenderNumber = 0;
  repeatDay = null as number | null;
  repeatDateType = null as number | null;
  repeatTitle = null as string | null;
  repeatText = null as string | null;

  // チケット利用促進配信ダイアログ
  showTicketDialog = false;
  ticketSenderNumber = 0;
  ticketRemainingDay = null as number | null;
  ticketDay = null as number | null;
  ticketDateType = null as number | null;
  ticketTitle = null as string | null;
  ticketText = null as string | null;
  ticketStatus = null as number | null;

  inputParams = {
    /** 本店フラグ */
    isMain: 0,
    /** プッシュリンクフラグ */
    pushLinkFlg: 0,
    /** デジタルチケットフラグ */
    paidTicketFlg: 0,
    /** 再来店促進配信利用フラグ */
    repeatFlg: 0 as number,
    /** 対象スタンプカード */
    stampSeqs: [] as string[] | null,
    /** 再来店促進配信情報配列 */
    repeat: [] as GetRepeat[] | null,
    /** 次回来店日配信利用フラグ */
    nextFlg: 0 as number,
    /** 次回来店日配信情報 */
    next: {} as GetNext | null,
    /** コンプリートチケット利用促進配信利用フラグ */
    completeFlg: 0 as number,
    /** コンプリートチケット利用促進配信情報 */
    complete: {} as GetComplete | null,
    /** 誕生月お知らせ配信利用フラグ */
    birthdayFlg: 0 as number,
    /** 誕生月お知らせ配信情報 */
    birthday: {} as GetBirthday | null,
    /** ストアレビューポップアップ利用フラグ */
    reviewFlg: 0 as number,
    /** ストアレビューポップアップ情報 */
    review: {} as GetReview | null,
    /** アンケートリンク配信利用フラグ */
    linkFlg: 0 as number,
    /** アンケートリンク配信情報 */
    link: { dateType: 1 } as GetLink | null,
    /** チケット利用促進配信利用フラグ */
    ticketFlg: 0 as number,
    /** チケット利用促進配信情報 */
    ticket: [] as GetTicket[] | null,
    /** スタンプポイント利用フラグ */
    stampPointFlg: 0 as number,
    /** スタンプ予約利用フラグ */
    stampBookingFlg: 0 as number
  } as GetResult;

  // OEM事業者ID
  businessId = 0 as number;

  // アンケートリンク配信
  showGeneralQuestion = false;
  showShopQuestion = false;
  shopIds = [] as number[];
  generalQuestion = [] as GetParams[];
  shopQuestion = [] as ShopQuestion[];

  // 再来店促進配信
  repeatStampSeqs = [] as number[];
  repeatUserCount = 0;

  // 次回来店日配信
  nextStampSeqs = [] as number[];
  nextUserCount = 0;

  // コンプリートチケット配信
  completeStampSeqs = [] as number[];
  completeUserCount = 0;

  // 誕生月お知らせ配信
  birthdayUserCount = 0;

  // ストラレビューポップアップ
  reviewUserCount = 0;

  // アンケートリンク配信
  linkUserCount = 0;

  // チケット利用促進配信
  sendTickets = [] as number[];

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get shopItems() {
    return ShitenListGet.getItems;
  }

  get promotionStampItems() {
    return StampListGet.getItems.promotion;
  }

  get nextStampItems() {
    return StampListGet.getItems.next;
  }

  get completeStampItems() {
    return StampListGet.getItems.complete;
  }

  get ticketItems() {
    return TicketListGet.getItems;
  }

  get multiFlg() {
    return StampListGet.getMultiFlg;
  }

  get sendTermType() {
    return [
      { id: 1, name: "日" },
      { id: 2, name: "ヶ月" },
      { id: 3, name: "年" }
    ];
  }

  get nextMonth() {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date.getMonth() + 1;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    let getErrorMessage = null as string | null;
    const remind = RemindGet.get({ id: Number(this.shopId) } as RemindRequest);
    const stamp = StampListGet.get({ id: Number(this.shopId) } as StampRequest);
    const shiten = ShitenListGet.get({
      id: Number(this.shopId)
    } as ShitenRequest);
    const ticket = TicketListGet.get({
      id: Number(this.shopId)
    } as TicketRequest);
    await Promise.all([remind, stamp, shiten, ticket]);
    if (!RemindGet.isSuccess) {
      getErrorMessage = RemindGet.getMessage;
    }
    if (!StampListGet.isSuccess) {
      getErrorMessage = StampListGet.getMessage;
    }
    if (!ShitenListGet.isSuccess) {
      getErrorMessage = ShitenListGet.getMessage;
    }
    if (!TicketListGet.isSuccess) {
      getErrorMessage = TicketListGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
      return;
    }
    this.syncInputParams(RemindGet.getResults);
  }

  async beforeDestroy() {
    await RemindGet.clearResponse();
    await RemindRegister.clear();
    await RemindCountGet.clearResponse();
    await StampListGet.clearResponse();
    await ShitenListGet.clearResponse();
    await TicketListGet.clearResponse();
    await PushUserCountGet.clearResponse();
  }

  async mounted() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }

  /**
   * 固定パラメータの追加ボタンを表示するか否か
   *
   * @param index
   */
  isShowAddGeneralParamButton(index: number) {
    if (this.generalQuestion) {
      return this.generalQuestion.length === index + 1;
    } else {
      return false;
    }
  }

  /**
   * 支店パラメータの追加ボタンを表示するか否か
   *
   * @param index
   */
  isShowAddShopParamButton(index: number) {
    if (this.shopQuestion) {
      return this.shopQuestion.length === index + 1;
    } else {
      return false;
    }
  }

  /**
   * 固定パラメータの追加ボタンが押された際のコールバック
   */
  addGeneralParamClickCallback() {
    const question = {
      type: 1,
      name: null,
      value: null
    } as GetParams;
    if (this.generalQuestion) {
      this.generalQuestion.push(question);
    } else {
      this.generalQuestion = [question];
    }
  }

  /**
   * 固定パラメータの追加ボタンが押された際のコールバック
   */
  addShopParamClickCallback() {
    const question = {
      name: "",
      values: {}
    } as ShopQuestion;
    if (this.shopQuestion) {
      this.shopQuestion.push(question);
    } else {
      this.shopQuestion = [question];
    }
  }

  /**
   * 固定パラメータの削除ボタンが押された際のコールバック
   *
   * @param index
   */
  removeGeneralParamClickCallback(index: number) {
    if (this.generalQuestion) {
      this.generalQuestion.splice(index, 1);
      if (this.generalQuestion.length <= 0) {
        // 配列が空になった場合は未入力のオブジェクトをpushする
        this.addGeneralParamClickCallback();
      }
    }
  }

  /**
   * 固定パラメータの削除ボタンが押された際のコールバック
   *
   * @param index
   */
  removeShopParamClickCallback(index: number) {
    if (this.shopQuestion) {
      this.shopQuestion.splice(index, 1);
      if (this.shopQuestion.length <= 0) {
        // 配列が空になった場合は未入力のオブジェクトをpushする
        this.addShopParamClickCallback();
      }
    }
  }

  repeatFocusOutCallback() {
    if (this.repeatDay && this.repeatDateType) {
      this.fetchRepeatPushCount().then();
    }
  }

  linkFocusOutCallback() {
    if (
      this.inputParams.link &&
      this.inputParams.link.day &&
      this.inputParams.link.dateType
    ) {
      this.fetchLinkPushCount().then();
    }
  }

  reviewFocusOutCallback() {
    if (
      this.inputParams.review &&
      this.inputParams.review.min &&
      this.inputParams.review.max
    ) {
      this.fetchReviewPushCount().then();
    }
  }

  nextTabChangeCallback(val: number) {
    if (val === 1) {
      this.fetchNextPushCount().then();
    }
  }

  completeTabChangeCallback(val: number) {
    if (val === 1) {
      this.fetchCompletePushCount().then();
    }
  }

  birthdayTabChangeCallback(val: number) {
    if (val === 1) {
      this.fetchBirthdayPushCount().then();
    }
  }

  /**
   * 再来店促進配信のパラメータを追加する
   */
  addRepeat(num: number) {
    const repeat = {
      num: num,
      day: null,
      dateType: 1,
      title: null,
      text: null,
      stampSeqs: [this.shopId],
      count: null
    } as GetRepeat;
    if (this.inputParams.repeat) {
      this.inputParams.repeat.push(repeat);
    } else {
      this.inputParams.repeat = [repeat];
    }
  }

  /**
   * チケット利用促進配信のパラメータを追加する
   */
  addTicket(num: number) {
    const ticket = {
      num: num,
      status: 1,
      day: null,
      dateType: 1,
      title: null,
      text: null,
      sendTicket: [this.shopId],
      count: null
    } as GetTicket;
    if (this.inputParams.ticket) {
      this.inputParams.ticket.push(ticket);
    } else {
      this.inputParams.ticket = [ticket];
    }
  }

  /**
   * リピートパラメータの削除
   */
  removeRepeatOnDialog() {
    this.showVisitDialog = false;
    if (this.inputParams.repeat) {
      this.inputParams.repeat = this.inputParams.repeat.filter(
        r => r.num !== this.visitSenderNumber
      );
      if (this.inputParams.repeat.length <= 0) {
        // 配列が空になった場合は未入力のオブジェクトをpushする
        this.addRepeat(1);
      }
    }
  }

  /**
   * チケットパラメータの削除
   */
  removeTicketOnDialog() {
    this.showTicketDialog = false;
    if (this.inputParams.ticket) {
      this.inputParams.ticket = this.inputParams.ticket.filter(
        r => r.num !== this.ticketSenderNumber
      );
      if (this.inputParams.ticket.length <= 0) {
        // 配列が空になった場合は未入力のオブジェクトをpushする
        this.addTicket(1);
      }
    }
  }

  saveRepeatOnDialog() {
    this.showVisitDialog = false;
    if (!this.inputParams.repeat) {
      this.inputParams.repeat = [];
    }
    for (let i = 0; i < this.inputParams.repeat.length; i++) {
      if (this.inputParams.repeat[i].num === this.visitSenderNumber) {
        this.inputParams.repeat[i].day = this.repeatDay;
        this.inputParams.repeat[i].dateType = this.repeatDateType;
        this.inputParams.repeat[i].title = this.repeatTitle;
        this.inputParams.repeat[i].text = this.repeatText;
        return;
      }
    }
    this.inputParams.repeat.push({
      count: null,
      num: this.visitSenderNumber,
      day: this.repeatDay,
      dateType: this.repeatDateType,
      title: this.repeatTitle,
      text: this.repeatText
    });
  }

  saveTicketOnDialog() {
    this.showTicketDialog = false;
    if (!this.inputParams.ticket) {
      this.inputParams.ticket = [];
    }

    for (let i = 0; i < this.inputParams.ticket.length; i++) {
      if (this.inputParams.ticket[i].num === this.ticketSenderNumber) {
        this.inputParams.ticket[i].day =
          this.ticketStatus === 1 ? this.ticketRemainingDay : this.ticketDay;
        this.inputParams.ticket[i].dateType = this.ticketDateType;
        this.inputParams.ticket[i].title = this.ticketTitle;
        this.inputParams.ticket[i].text = this.ticketText;
        this.inputParams.ticket[i].status = this.ticketStatus;
        return;
      }
    }
    this.inputParams.ticket.push({
      count: null,
      num: this.ticketSenderNumber,
      day: this.ticketStatus === 1 ? this.ticketRemainingDay : this.ticketDay,
      dateType: this.ticketDateType,
      title: this.ticketTitle,
      text: this.ticketText,
      sendTicket: [],
      status: this.ticketStatus
    });
  }

  /**
   * 店舗別パラメータの店舗選択が変更された際のコールバック
   */
  changeShopSelectCallback() {
    for (let i in this.shopQuestion) {
      let v = {} as { [key: number]: string };
      for (let shopId of this.shopIds) {
        v[shopId] = this.shopQuestion[i].values[shopId];
      }
      this.shopQuestion[i].values = v;
    }
  }

  /**
   * 再来店促進配信設定ダイアログボタンのコールバック
   *
   * @param num
   */
  openVisitDialogCallback(num: number) {
    this.visitSenderNumber = num;
    this.showVisitDialog = true;
    if (this.inputParams.repeat) {
      const repeat = this.inputParams.repeat.find(r => r.num === num);
      if (repeat) {
        this.repeatDay = repeat.day;
        this.repeatDateType = repeat.dateType;
        this.repeatTitle = repeat.title;
        this.repeatText = repeat.text;
        // 20201214 NEW_DEV-734 C34-No.38 cyber 秦 start
        this.repeatFocusOutCallback();
        // 20201214 NEW_DEV-734 C34-No.38 cyber 秦 end
      } else {
        this.repeatDay = null;
        this.repeatDateType = 1;
        this.repeatTitle = null;
        this.repeatText = null;
      }
    }
  }

  /**
   * チケット利用促進配信設定ダイアログボタンのコールバック
   *
   * @param num
   */
  openTicketDialogCallback(num: number) {
    this.ticketSenderNumber = num;
    this.showTicketDialog = true;
    if (this.inputParams.ticket) {
      const ticket = this.inputParams.ticket.find(r => r.num === num);
      if (ticket) {
        this.ticketStatus = ticket.status;
        if (ticket.status === 1) {
          this.ticketRemainingDay = ticket.day;
          this.ticketDay = null;
        } else {
          this.ticketRemainingDay = null;
          this.ticketDay = ticket.day;
        }
        this.ticketDateType = ticket.dateType;
        this.ticketTitle = ticket.title;
        this.ticketText = ticket.text;
      } else {
        this.ticketStatus = 1;
        this.ticketRemainingDay = null;
        this.ticketDay = null;
        this.ticketDateType = 1;
        this.ticketTitle = null;
        this.ticketText = null;
      }
    }
  }

  /**
   * 画面内リンクのコールバック
   * @param hash
   */
  scrollCallback(hash: string) {
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView(true);
    }
  }

  /**
   * 店舗名を返却する
   *
   * @param id
   */
  getShopName(id: number) {
    const shop = this.shopItems.find(s => s.id === id);
    return shop ? shop.name : "";
  }

  repeatButtonEnable(num: number) {
    if (num <= 1) {
      return true;
    } else {
      if (this.inputParams.repeat) {
        return num <= this.inputParams.repeat.length + 1;
      }
    }
  }

  ticketButtonEnable(num: number) {
    if (num <= 1) {
      return true;
    } else {
      if (this.inputParams.ticket) {
        return num <= this.inputParams.ticket.length + 1;
      }
    }
  }

  async fetchRepeatPushCount() {
    // 20201214 NEW_DEV-734 C34-No.38 cyber 秦 start
    const request = {
      id: Number(this.shopId),
      remindType: 1,
      stampSeqs: this.repeatStampSeqs,
      day: this.repeatDay,
      dateType: this.repeatDateType
    } as RemindCountRequest;
    await this.callRemindCountApi(request);
    if (RemindCountGet.isSuccess) {
      this.repeatUserCount = RemindCountGet.getCount;
    }
    // 20201214 NEW_DEV-734 C34-No.38 cyber 秦 end
  }

  async fetchNextPushCount() {
    // 20201215 NEW_DEV-734 C34-No.65 cyber 秦 start
    // NEW_DEV-1757 cyber start
    if (this.inputParams.next && this.inputParams.next.day) {
    // NEW_DEV-1757 cyber end
      const request = {
        id: Number(this.shopId),
        remindType: 2,
        stampSeqs: this.nextStampSeqs,
        day: this.inputParams.next ? this.inputParams.next.day : null,
        dateType: 1
      } as RemindCountRequest;
      await this.callRemindCountApi(request);
      if (RemindCountGet.isSuccess) {
        this.nextUserCount = RemindCountGet.getCount;
      }
    }else{
      this.nextUserCount = 0;
    }
    // 20201215 NEW_DEV-734 C34-No.65 cyber 秦 end
  }

  async fetchCompletePushCount() {
    // 20201215 NEW_DEV-734 C34-No.84 cyber 秦 start
    if (this.completeStampSeqs.length > 0 && this.inputParams.complete && this.inputParams.complete.day) {
      const request = {
        id: Number(this.shopId),
        remindType: 3,
        stampSeqs: this.completeStampSeqs,
        day: this.inputParams.complete ? this.inputParams.complete.day : null,
        dateType: 1
      } as RemindCountRequest;
      await this.callRemindCountApi(request);
      if (RemindCountGet.isSuccess) {
        this.completeUserCount = RemindCountGet.getCount;
      }
    }else{
      this.completeUserCount = 0;
    }
    // 20201215 NEW_DEV-734 C34-No.84 cyber 秦 end
  }

  async fetchBirthdayPushCount() {
    /*
    const request = {
      id: Number(this.shopId),
      remindType: 4
    } as RemindCountRequest;
    await this.callRemindCountApi(request);
    if (RemindCountGet.isSuccess) {
      this.birthdayUserCount = RemindCountGet.getCount;
    }
    */
  }

  async fetchLinkPushCount() {
    // 20201214 NEW_DEV-734 C34-No.131 cyber 董 start
    const request = {
      id: Number(this.shopId),
      remindType: 5,
      day: this.inputParams.link ? this.inputParams.link.day : null,
      dateType: this.inputParams.link ? this.inputParams.link.dateType : null
    } as RemindCountRequest;
    await this.callRemindCountApi(request);
    if (RemindCountGet.isSuccess) {
      this.linkUserCount = RemindCountGet.getCount;
    }
    // 20201214 NEW_DEV-734 C34-No.131 cyber 董 end
  }

  async fetchReviewPushCount() {
    const request = {
      id: Number(this.shopId),
      remindType: 7,
      min: this.inputParams.review ? this.inputParams.review.min : null,
      max: this.inputParams.review ? this.inputParams.review.max : null
    } as RemindCountRequest;
    await this.callRemindCountApi(request);
    if (RemindCountGet.isSuccess) {
      this.reviewUserCount = RemindCountGet.getCount;
    }
  }

  /**
   * 登録処理
   */
  async register() {
    await RemindRegister.register(this.createRegisterRequest());
    if (RemindRegister.isSuccess) {
      await Flash.clear();
      await this.redirectWithSuccessAlert(
        "プッシュメッセージ自動配信設定を保存しました。",
        `/push-notifications`
      );
    } else {
      await Flash.setErrorNow({
        message: RemindRegister.getMessage,
        showReloadButton: false
      });
    }
  }
  // 20201204 NEW_DEV-734 C34-No.24、No.25 cyber 秦 start
  /**
   * スタンプカード新規登録・編集画面に遷移する
   */
  goToNew() {
    this.$router
      .push({
        name: "stamps-new",
        query: { shopId: this.shopId, shopName: this.shopName }
      })
      .then();
  }

  /**
   * ポイントカード新規登録・編集画面に遷移する
   */
  goToSetting() {
    this.$router
      .push({
        name: "points-setting",
        params: { shopId: String(this.shopId) },
        query: { shopName: this.shopName }
      })
      .then();
  }
  // 20201204 NEW_DEV-734 C34-No.24、No.25 cyber 秦 end
  private syncInputParams(getResult: GetResult) {
    this.inputParams = _.cloneDeep(getResult);
    if (this.inputParams.stampSeqs) {
      this.repeatStampSeqs = (
        this.inputParams.stampSeqs || ([] as string[])
      ).map(s => {
        return Number(s);
      });
      // 20201214 NEW_DEV-734 C34-No.38 cyber 秦 start
      //this.repeatUserCount = this.repeatStampSeqs.length;
      this.repeatFocusOutCallback();
      // 20201214 NEW_DEV-734 C34-No.38 cyber 秦 end
    }
    if (this.inputParams.next) {
      this.nextStampSeqs = (
        this.inputParams.next.stampSeqs || ([] as string[])
      ).map(s => {
        return Number(s);
      });
      this.nextUserCount = this.inputParams.next.count || 0;
    }
    if (this.inputParams.complete) {
      this.completeStampSeqs = (
        this.inputParams.complete.stampSeqs || ([] as string[])
      ).map(s => {
        return Number(s);
      });
      this.completeUserCount = this.inputParams.complete.count || 0;
    }
    if (this.inputParams.birthday) {
      this.birthdayUserCount = this.inputParams.birthday.count || 0;
    }
    if (this.inputParams.review) {
      this.reviewUserCount = this.inputParams.review.count || 0;
    }
    if (this.inputParams.link && this.inputParams.link.params) {
      this.linkUserCount = this.inputParams.link.count || 0;
      // 固定パラメータの同期
      this.generalQuestion = this.inputParams.link.params.filter(
        q => q.type === 1
      );
      if (this.generalQuestion && this.generalQuestion.length > 0) {
        this.showGeneralQuestion = true;
      } else {
        this.addGeneralParamClickCallback();
      }
      // 支店別パラメータの同期
      const shopQuestion = this.inputParams.link.params.filter(
        q => q.type === 2
      );
      this.shopQuestion = [];
      for (let question of shopQuestion) {
        const shopId = question.shopId;
        if (!shopId) {
          continue;
        }

        // 20201208 NEW_DEV-734 C34-No.162 cyber 董 start
        if(this.shopIds.length > 0){
          if(this.shopIds.indexOf(shopId) >= 0){
            //Nothing to do
          }else{
            this.shopIds.push(shopId);
          }
        }else{
          this.shopIds.push(shopId);
        }
        // 20201208 NEW_DEV-734 C34-No.162 cyber 董 end

        let exists = false;
        for (let i = 0; i < this.shopQuestion.length; i++) {
          if (this.shopQuestion[i].name === question.name) {
            // 同じパラメータ名が存在する場合
            if (question.value) {
              this.shopQuestion[i].values[shopId] = question.value;
            }
            exists = true;
            break;
          }
        }
        if (!exists) {
          // 同じパラメータ名が存在しない場合
          const newQuestion = {
            name: question.name,
            values: {}
          } as ShopQuestion;
          if (question.value) {
            newQuestion.values[shopId] = question.value;
          }
          this.shopQuestion.push(newQuestion);
        }
      }
      if (shopQuestion.length > 0) {
        this.showShopQuestion = true;
      } else {
        this.addShopParamClickCallback();
      }
    } else {
      // デフォルト
      const question = {
        type: 1,
        name: null,
        value: null
      } as GetParams;
      this.generalQuestion.push(question);

      const newQuestion = {
        name: question.name,
        values: {}
      } as ShopQuestion;
      this.shopQuestion.push(newQuestion);
    }
    if (this.inputParams.ticket) {
      this.sendTickets = (
        this.inputParams.ticketIds || ([] as string[])
      ).map(s => {
        return Number(s);
      });
    }
  }

  private async callRemindCountApi(request: RemindCountRequest) {
    await RemindCountGet.get(request);
    if (!RemindCountGet.isSuccess) {
      await Flash.setErrorNow({
        message: RemindCountGet.getMessage,
        showReloadButton: false
      });
    }
  }

  private createRegisterRequest() {
    const params = { ...this.inputParams };
    //delete this.inputParams.isMain;
    //delete this.inputParams.pushLinkFlg;
    //delete this.inputParams.paidTicketFlg;
    const request = { id: Number(this.shopId), ...params } as RegisterRequest;
    // 再来店促進配信設定
    if (request.repeatFlg === 1) {
      request.stampSeqs = this.repeatStampSeqs.map(s => String(s));
    } else {
      delete request.repeat;
    }
    // 次回来店日配信設定
    if (request.nextFlg === 1 && request.next) {
      request.next = {
        text: request.next.text,
        day: request.next.day,
        title: request.next.title,
        stampSeqs: []
      };
      if (this.nextStampSeqs.length > 0) {
        request.next.stampSeqs = this.nextStampSeqs.map(s => String(s));
      }
    } else {
      delete request.next;
    }
    // コンプリートチケット利用促進配信設定
    if (request.completeFlg === 1 && request.complete) {
      request.complete = {
        text: request.complete.text,
        day: request.complete.day,
        title: request.complete.title,
        stampSeqs: []
      };
      if (this.completeStampSeqs.length > 0) {
        request.complete.stampSeqs = this.completeStampSeqs.map(s => String(s));
      }
    } else {
      delete request.complete;
    }
    // お誕生日お知らせ配信設定
    if (request.birthdayFlg === 0) {
      delete request.birthday;
    } else {
      request.birthday = {
        title: request.birthday.title,
        text: request.birthday.text,
      };
    }

    if (this.inputParams.pushLinkFlg === 1 && request.linkFlg === 1) {
      request.link = {
        url: this.inputParams.link.url,
        day: this.inputParams.link.day,
        date_type: this.inputParams.link.dateType,
        title: this.inputParams.link.title,
        text: this.inputParams.link.text,
        params: []
      };
      if (this.showGeneralQuestion) {
        for (const p of this.generalQuestion) {
          request.link.params = _.cloneDeep(this.generalQuestion);
        }
        //20201225 NEW_DEV-734 C34-No.157,160 cyber 李 start
      }
      if (this.showShopQuestion) {
        //20201225 NEW_DEV-734 C34-No.157,160 cyber 李 end
        for (const p of this.shopQuestion) {
          for (const shopId in p.values) {
            request.link.params.push({
              shopId: Number(shopId),
              type: 2,
              name: p.name,
              value: p.values[shopId]
            } as RegisterParams);
          }
        }
      }
    } else {
      delete request.link;
    }
    // ストアレビューポップアップ設定
    if (this.inputParams.isMain === 0 || request.reviewFlg === 0) {
      delete request.review;
    }
    if (request.reviewFlg === 1 && request.isMain == 1) {
      request.review = {
        min: request.review.min,
        max: request.review.max
      };
    } else if(request.reviewFlg === 1) {
      request.review = {
        min: 0,
        max: 0
      };
    } else {
      delete request.review;
    }

    // チケット利用促進配信設定
    if (this.inputParams.paidTicketFlg === 1 && request.ticketFlg === 1) {
      /*
      for (let i = 0; i < request.ticket.length; i++) {
        request.ticket[i].sendTicket = _.cloneDeep(this.sendTickets);
      }*/
      request.ticketShopId = this.inputParams.ticketShopId;
      if (request.ticket) {
        request.ticket = this.inputParams.ticket;
      }
      if (this.sendTickets.length > 0) {
        request.ticketIds = this.sendTickets.map(s => String(s));
      }
    } else {
      delete request.ticket;
    }
    return request;
  }

}
