import service from "@/api/service";
import { GetResponse } from "@/api/stamp-list/response";
import { GetRequest } from "@/api/stamp-list/request";

/**
 * プッシュメッセージスタンプカード取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-stamp-list", getRequest);
  return response.data as GetResponse;
}
