import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetDetail, GetItem, GetResponse } from "@/api/stamp-list/response";
import * as StampListAPI from "@/api/stamp-list";
import { isSuccess } from "@/api/response";
import { GetRequest } from "@/api/stamp-list/request";

const MODULE_NAME = "stamp-list/get";

/**
 * プッシュメッセージスタンプカード取得API（/get-stamp-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getMultiFlg() {
    if (this.getResponse.results) {
      return this.getResponse.results.multiFlg | 0;
    } else {
      return 0;
    }
  }

  get getItems() {
    if (this.getResponse.results && this.getResponse.results.items) {
      return this.getResponse.results.items;
    } else {
      return {
        next: [] as GetDetail[],
        complete: [] as GetDetail[],
        promotion: [] as GetDetail[]
      } as GetItem;
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await StampListAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
